import { IStoreContext, StoreContext } from './StoreContext';
import React, { useMemo } from 'react';

import { PricesAndInventoriesCache } from '../../engine/PricesAndInventoriesCache';
import { useAllProductsPricesSSR } from './gatsby';
import { useBoutikService } from '../boutikService';
import { usePageLocalization } from '@sbi-international/boutik.gatsby.plugin.multilocale';

export interface IStoreProviderProps {
    children: React.ReactNode;
}

// FIXME: Sim: Get this mapping from configuration
const localeCodeToStoreViewCodeMapping: { [locale: string]: string } = {
    fr_CA: 'drolet_fr_ca',
    en_CA: 'drolet_en_ca',
    en_US: 'drolet_en_us',
};

export const StoreProvider: React.FC<IStoreProviderProps> = ({ children }) => {
    const { boutikService } = useBoutikService();

    // FIXME: Need to find a proper way to do this
    const { currentLocale } = usePageLocalization();
    let currentStore: string | undefined = undefined;
    if (currentLocale) {
        currentStore = localeCodeToStoreViewCodeMapping[currentLocale];
        boutikService.storeViewCode = currentStore;
    }

    // JWH: This is a temp fix until we implement a better strategy.
    const allProductsPricesSSR = useAllProductsPricesSSR();
    const pricesAndInventoriesCache = useMemo<PricesAndInventoriesCache>(
        () => {
            const cache = new PricesAndInventoriesCache(
                boutikService,
                currentStore as string
            );
            cache.setAllProductsPricesSSR(
                allProductsPricesSSR[`magento_${currentStore}`]
            );
            return cache;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [boutikService, currentStore]
    );

    const contextValue = useMemo<IStoreContext>(() => {
        return {
            pricesAndInventoriesCache,
            currentStore,
        };
    }, [pricesAndInventoriesCache, currentStore]);

    return (
        <StoreContext.Provider value={contextValue}>
            {children}
        </StoreContext.Provider>
    );
};
