import {
    IProductInventory,
    IProductPrice,
} from '../../../engine/PricesAndInventoriesCache';
import { useEffect, useMemo, useState } from 'react';

import { IStoreContextInternalAPI } from '../StoreContext';
import { useStore } from '../useStore';

export function useProductPrice(sku: string, options?: { maxAge?: number }) {
    const { pricesAndInventoriesCache, currentStore } =
        useStore() as IStoreContextInternalAPI;
    const productResource = useMemo(() => {
        if (!currentStore) throw new Error('Current Store is not yet defined');

        return pricesAndInventoriesCache.getProductResource(sku);
    }, [pricesAndInventoriesCache, currentStore, sku]);

    const [price, setPrice] = useState<IProductPrice | undefined>(() =>
        productResource.getPriceImmediate()
    );

    useEffect(() => {
        setPrice(productResource.getPriceImmediate());
        productResource.subscribePrice(setPrice, { maxAge: options?.maxAge });

        return () => {
            productResource.unsubscribePrice(setPrice);
        };
    }, [productResource, options, setPrice]);

    return [price] as const;
}

// FIXME: This hook is only used for the filter-by-price usecase. That specific component has no SSR
//        requirement. Using hooks for this purpose is difficult, innefficient, and not totaly reliable.
//        That component should be updated to obtain prices another way (using a query API directly
//        to the cache).
export function useProductsPrices(
    skus: string[],
    options?: { maxAge?: number }
) {
    const { pricesAndInventoriesCache, currentStore } =
        useStore() as IStoreContextInternalAPI;
    const productsResources = useMemo(() => {
        if (!currentStore) throw new Error('Current Store is not yet defined');

        return skus.map((sku) =>
            pricesAndInventoriesCache.getProductResource(sku)
        );
    }, [pricesAndInventoriesCache, currentStore, skus]);

    const [prices, setPrices] = useState<(IProductPrice | undefined)[]>(() =>
        productsResources.map((productResource) =>
            productResource.getPriceImmediate()
        )
    );

    // useEffect(() => {
    //     // The useState(...) above will have set prices correctly on initial render of this hook,
    //     // and is required to have Gatsby SSR work correctly. However, if this hook inputs get
    //     // changed at a later time, the price array might not be the correct size. For this reason,
    //     // we recalculate the price array here, and immediately set it as the current prices array.
    //     let refreshablePrices = productsResources.map(
    //         (productResource) => productResource.getPriceImmediate()
    //     );
    //     setPrices(refreshablePrices);

    //     let setters = productsResources.map(
    //         (productResource, index) => {
    //             let setter = (newValue: IProductPrice | undefined) => {
    //                 refreshablePrices[index] = newValue;
    //                 setPrices([...refreshablePrices]);
    //             }

    //             productResource.subscribePrice(setter, { maxAge: options?.maxAge })

    //             return [productResource, setter] as const;
    //         }
    //     )

    //     return () => {
    //         for (let [productResource, setter] of setters)
    //             productResource.unsubscribePrice(setter);

    //         setPrices([]);
    //     };
    // }, [productsResources, options, setPrices]);

    return [prices] as const;
}

export function useProductsPricesAndCode(skus: string[]) {
    const { pricesAndInventoriesCache, currentStore } =
        useStore() as IStoreContextInternalAPI;
    const productsResources = useMemo(() => {
        if (!currentStore) throw new Error('Current Store is not yet defined');

        return skus.map((sku) => {
            return {
                price: pricesAndInventoriesCache.getProductResource(sku),
                sku: sku,
            };
        });
    }, [pricesAndInventoriesCache, currentStore, skus]);

    const [prices, setPrices] = useState<(any | undefined)[]>(() =>
        productsResources.map((productResource) => {
            return {
                price: productResource?.price?.getPriceImmediate(),
                sku: productResource.sku,
            };
        })
    );
    return [prices] as const;
}

export function useProductInventory(
    sku: string,
    options?: { maxAge?: number }
) {
    const { pricesAndInventoriesCache, currentStore } =
        useStore() as IStoreContextInternalAPI;

    const productResource = useMemo(() => {
        if (!currentStore) throw new Error('Current Store is not yet defined');

        return pricesAndInventoriesCache.getProductResource(sku);
    }, [pricesAndInventoriesCache, currentStore, sku]);

    const [inventory, setInventory] = useState<IProductInventory | undefined>(
        () => productResource.getInventoryImmediate()
    );

    useEffect(() => {
        setInventory(productResource.getInventoryImmediate());
        productResource.subscribeInventory(setInventory, {
            maxAge: options?.maxAge,
        });
        return () => {
            productResource.unsubscribeInventory(setInventory);
        };
    }, [productResource, options]);

    return [inventory] as const;
}
