import dayjs from 'dayjs';
import {
    ProductsPriceAndInventoriesDocument,
    ProductsPriceAndInventoriesQuery,
    ProductsPriceAndInventoriesQueryVariables,
} from '../context/store/graphql';

import { BatchExecutor } from '../utils/BatchExecutor';
import { BoutikService } from './BoutikService';

export interface IProductPrice {
    regularPrice: {
        value?: number;
        currency?: string;
    };
    finalPrice: {
        value?: number;
        currency?: string;
    };
    discount?: {
        percentOff?: number;
        amountOff?: number;
    };
}

export interface IProductInventory {
    stock?: {
        inStock: boolean;
        status: 'out-of-stock' | 'in-stock' | 'preorder';
        onlyXLeftInStock?: number;
        nextAvailableDate?: string;
        specialPriceEndDate?: string;
        specialPriceFromDate?: string;
    };
}

type IPriceSubscriptionFn = (price: IProductPrice) => void;
type IInventorySubscriptionFn = (inventory: IProductInventory) => void;

const DEFAULT_PRICE_MAX_AGE: number = 12 * 60 * 60; // 12 hours
const DEFAULT_INVENTORY_MAX_AGE: number = 15 * 60; // 15 minutes
const NEGATIVE_RESPONSE_CACHE_AGE: number = 15 * 60; // 15 minutes

export class PricesAndInventoriesCache {
    private boutik: BoutikService;
    private currentStore: string;

    private canDoAsync: boolean = typeof window !== 'undefined';
    private fetchDelay = 100; // Wait that much time (ms) before actually fetching data from server

    private resources: Map<string, ProductPriceAndInventoryResource> =
        new Map();
    private queryBatchExecutor: BatchExecutor<string>;

    constructor(boutik: BoutikService, currentStore: string) {
        this.boutik = boutik;
        this.currentStore = currentStore;
        this.queryBatchExecutor = new BatchExecutor(
            this.doQuery.bind(this),
            this.fetchDelay
        );
    }

    // JWH: This is a temp fix until we can implement a better strategy.
    public setAllProductsPricesSSR(allProductsPricesSSR: {
        products: { items: IGraphQLProductItem[] };
    }) {
        if (allProductsPricesSSR) {
            for (const item of allProductsPricesSSR.products.items) {
                if (!item?.sku) return;

                this.getProductResource(item.sku).refreshed(
                    toPriceAndInventory(item, this.currentStore as string)
                );
            }
        }
    }

    public getProductResource(sku: string): ProductPriceAndInventoryResource {
        let resource = this.resources.get(sku);
        if (!resource) {
            resource = new ProductPriceAndInventoryResource(
                this.queryBatchExecutor,
                sku
            );
            this.resources.set(sku, resource);
        }
        return resource;
    }

    private async doQuery(skus: Set<string>) {
        // Make a copy, so we can modify it later on
        skus = new Set(skus);

        try {
            const result = await this.boutik.apolloClient.query<
                ProductsPriceAndInventoriesQuery,
                ProductsPriceAndInventoriesQueryVariables
            >({
                query: ProductsPriceAndInventoriesDocument,
                variables: { skus: Array.from(skus) },
                fetchPolicy: 'network-only',
            });

            if (result.data.products?.items)
                for (const item of result.data.products?.items) {
                    if (!item?.sku) return;

                    // Mark that sku as resolved
                    skus.delete(item.sku);

                    this.getProductResource(item.sku).refreshed(
                        toPriceAndInventory(item, this.currentStore as string)
                    );
                }
        } catch (e) {
            console.error(e);
            throw e;
        } finally {
            // Mark remaining products as failed
            for (const sku of skus) {
                this.getProductResource(sku).refreshed(undefined);
            }
        }
    }
}

type ISubscription = [
    IPriceSubscriptionFn | IInventorySubscriptionFn,
    'price' | 'inventory',
    number
];

export class ProductPriceAndInventoryResource {
    private sku: string;

    private status:
        | 'unfetched'
        | 'price-only'
        | 'price-and-inventory'
        | 'failed' = 'unfetched';
    private details?: IProductPrice & IProductInventory = undefined;
    private fetchDate = 0;
    private lastFailedFetchDate?: number | undefined = 0;

    private queryExecutor: BatchExecutor<string>;

    private subscriptions: ISubscription[] = [];
    private cancelActiveTimer: (() => void) | null = null;

    constructor(queryExecutor: BatchExecutor<string>, sku: string) {
        if (typeof sku !== 'string' || !sku.match(/^[A-Za-z0-9-]+$/))
            throw new Error(`Not an acceptable SKU: '${sku}'`);

        this.sku = sku;
        this.queryExecutor = queryExecutor;

        this.updateTimer = this.updateTimer.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    public hasPrice() {
        return (
            this.status === 'price-only' ||
            this.status === 'price-and-inventory'
        );
    }

    public getPriceImmediate() {
        if (this.hasPrice()) return this.details as IProductPrice;
        return undefined;
    }

    public hasInventory() {
        return this.status === 'price-and-inventory';
    }

    public getInventoryImmediate() {
        if (this.hasInventory()) return this.details as IProductInventory;
        return undefined;
    }

    public getAge() {
        return Math.max(0, (Date.now() - this.fetchDate) / 1000);
    }

    public subscribePrice(
        callback: IPriceSubscriptionFn,
        options?: { maxAge?: number | undefined }
    ) {
        this.subscriptions.push([
            callback,
            'price',
            options?.maxAge ?? Number.MAX_VALUE,
        ]);
        this.updateTimer();
    }

    public unsubscribePrice(callback: IPriceSubscriptionFn) {
        this.subscriptions = this.subscriptions.filter(
            (x) => x[0] !== callback && x[1] === 'price'
        );
        this.updateTimer();
    }

    public subscribeInventory(
        callback: IInventorySubscriptionFn,
        options?: { maxAge?: number | undefined }
    ) {
        this.subscriptions.push([
            callback,
            'inventory',
            options?.maxAge ?? Number.MAX_VALUE,
        ]);
        this.updateTimer();
    }

    public unsubscribeInventory(callback: IInventorySubscriptionFn) {
        this.subscriptions = this.subscriptions.filter(
            (x) => x[0] !== callback && x[1] === 'inventory'
        );
        this.updateTimer();
    }

    private updateTimer() {
        if (this.cancelActiveTimer) {
            this.cancelActiveTimer();
            this.cancelActiveTimer = null;
        }

        if (this.subscriptions.length > 0) {
            let effectiveMaxAge = Number.MAX_VALUE;
            let needPrice = false;
            let needInventory = false;

            for (const subscription of this.subscriptions) {
                if (subscription[2] < effectiveMaxAge)
                    effectiveMaxAge = subscription[2];
                if (subscription[1] === 'price') needPrice = true;
                if (subscription[1] === 'inventory') needInventory = true;
            }

            if (effectiveMaxAge === Number.MAX_VALUE)
                effectiveMaxAge = needInventory
                    ? DEFAULT_INVENTORY_MAX_AGE
                    : DEFAULT_PRICE_MAX_AGE;

            const age = this.getAge();
            if (
                age < effectiveMaxAge &&
                !(needInventory && !this.hasInventory()) &&
                !(needPrice && !this.hasPrice())
            ) {
                // The products doesn't need to be refreshed. Wait until time has come for it to be refreshed.

                const timeoutHandle = setTimeout(
                    this.refresh,
                    (effectiveMaxAge - age) * 1000
                );
                this.cancelActiveTimer = () => clearTimeout(timeoutHandle);

                return;
            }

            if (typeof this.lastFailedFetchDate !== 'undefined') {
                const negativeResponseAge =
                    (Date.now() - this.lastFailedFetchDate) / 1000;
                if (negativeResponseAge < NEGATIVE_RESPONSE_CACHE_AGE) {
                    // The product needs to be refreshed, but last attempt failed.
                    // Wait until sufficient time has passed before querying again.

                    const timeoutHandle = setTimeout(
                        this.refresh,
                        (NEGATIVE_RESPONSE_CACHE_AGE - negativeResponseAge) *
                            1000
                    );
                    this.cancelActiveTimer = () => clearTimeout(timeoutHandle);

                    return;
                }
            }

            // Refresh the product immediately.

            this.refresh();
        }
    }

    public refresh() {
        this.queryExecutor.fetch(this.sku);
    }

    refreshed(details?: IProductPrice & IProductInventory) {
        if (typeof details === 'undefined') {
            this.lastFailedFetchDate = Date.now();
        } else if (typeof details.stock === 'undefined') {
            this.status = 'price-only';
            this.details = details;
            this.fetchDate = Date.now();
            this.lastFailedFetchDate = undefined;

            this.callSubscribers(['price']);
        } else {
            this.status = 'price-and-inventory';
            this.details = details;
            this.fetchDate = Date.now();
            this.lastFailedFetchDate = undefined;

            this.callSubscribers(['price', 'inventory']);
        }

        // Restart the timer, but don't hurry
        Promise.resolve().then(this.updateTimer);
    }

    private callSubscribers(which: ('price' | 'inventory')[]) {
        const callPriceSubscribers = which.includes('price');
        const callInventorySubscribers = which.includes('inventory');

        for (const subscription of this.subscriptions) {
            if (subscription[1] === 'price' && callPriceSubscribers)
                (subscription[0] as IPriceSubscriptionFn)(
                    this.details as IProductPrice
                );
            else if (
                subscription[1] === 'inventory' &&
                callInventorySubscribers
            )
                (subscription[0] as IInventorySubscriptionFn)(
                    this.details as IProductInventory
                );
        }
    }
}

export type StockDetails = {
    availabilityDate: string;
    quantity: number;
    sbiStockCode: string;
    status: 'in-stock' | 'out-of-stock' | 'preorder';
};

interface IGraphQLProductItem {
    sku?: string | null;
    stocks?: StockDetails[];
    priceRange?: {
        minimumPrice?: {
            regularPrice?: {
                value?: number | null;
                currency?: string | null;
            };
            finalPrice?: {
                value?: number | null;
                currency?: string | null;
            };
            discount?: {
                percentOff?: number | null;
                amountOff?: number | null;
            } | null;
        };
    };
    specialPriceEndDate?: string | null;
    specialPriceFromDate?: string | null;
}

export function isStock(
    item: IGraphQLProductItem,
    currentStore: string
): boolean {
    if (item.stocks) {
        const stockDetail = getLocalStock(
            item.stocks as StockDetails[],
            currentStore
        );
        return stockDetail?.status != 'out-of-stock';
    } else {
        return false;
    }
}

function getLocalStock(
    stockDetails: StockDetails[],
    currentStore: string
): StockDetails | undefined {
    const local = !currentStore || currentStore.includes('_ca') ? 'CA' : 'US';
    const stockDetail = stockDetails.find((s) => s.sbiStockCode == local);
    return stockDetail;
}

function toPriceAndInventory(item: IGraphQLProductItem, currentStore: string) {
    let stockDetail = undefined;
    if (item.stocks) {
        stockDetail = getLocalStock(
            item.stocks as StockDetails[],
            currentStore
        );
    }

    const price = item?.priceRange?.minimumPrice;
    if (!price) return undefined;

    const hasDiscount =
        !!price.discount?.percentOff || !!price.discount?.amountOff;

    return {
        regularPrice: {
            value: price.regularPrice?.value ?? undefined,
            currency: price.regularPrice?.currency ?? undefined,
        },
        finalPrice: {
            value: price.finalPrice?.value ?? undefined,
            currency: price.finalPrice?.currency ?? undefined,
        },
        discount: !hasDiscount
            ? undefined
            : {
                  percentOff: price.discount?.percentOff ?? undefined,
                  amountOff: price.discount?.amountOff ?? undefined,
              },
        stock: !stockDetail?.status
            ? undefined
            : {
                  inStock: stockDetail?.status != 'out-of-stock',
                  status: stockDetail?.status,
                  onlyXLeftInStock: stockDetail?.quantity ?? undefined,
                  nextAvailableDate: stockDetail?.availabilityDate ?? undefined,
                  specialPriceEndDate: item?.specialPriceEndDate ?? undefined,
                  specialPriceFromDate: item?.specialPriceFromDate ?? undefined,
              },
    };
}
